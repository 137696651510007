import { Maptype } from 'types/common.types';
import { LayersControl, Pane, TileLayer } from 'react-leaflet';
// import GoogleMapLayer from './GoogleMapLayer';

const { BaseLayer } = LayersControl;
interface MapLayersProps {
  maptype?: string;
}

const MapLayers = ({ maptype }: MapLayersProps) => {
  return (
    <Pane name="baseMapLayerPane" style={{ zIndex: 100 }}>
      <LayersControl position="topright">
        <BaseLayer checked={maptype === Maptype.Roadmap} name="ESRI Street Map">
          {/* <GoogleMapLayer maptype={Maptype.Roadmap} /> */}
          <TileLayer
            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}"
            attribution='&copy; <a href="http://www.esri.com/">Esri</a>'
          />
        </BaseLayer>
        <BaseLayer checked={maptype === Maptype.satellite} name="ESRI Satellite Map">
          {/* <GoogleMapLayer maptype={Maptype.satellite} /> */}
          <TileLayer
            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
            attribution='&copy; <a href="http://www.esri.com/">Esri</a>'
          />
        </BaseLayer>
      </LayersControl>
    </Pane>
  );
};

MapLayers.defaultProps = {
  maptype: Maptype.Roadmap
};

export default MapLayers;
