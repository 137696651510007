import { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import Control from 'react-leaflet-custom-control';
import { Button, Dropdown, MenuProps, Radio, Typography } from 'antd';
import MapLayerIcon from 'assets/svg/MapLayerIcon';
import MinusIcon from 'assets/svg/MinusIcon';
import PlusIcon from 'assets/svg/PlusIcon';
import RecenterIcon from 'assets/svg/RecenterIcon';
import { CoordinateType, Maptype } from 'types/common.types';
import { getCenterPosition, getCenterPositionPolygon } from 'utils/xml';
import { isEmpty } from 'lodash';
import { useAppSelector } from 'hooks/useReduxHook';

const { Text } = Typography;

interface MapActionControlVagIntProps {
  mapType: string;
  boundRef: any;
  handleMapType: (data: string) => void;
  handleOpenLayersList?: (isOpenList: boolean) => void;
  classname?: string;
  isOpenLayerList?: boolean;
  isProjectView?: boolean;
  selectedRiskData?: any;
  isRequestingRiskanalysis?: boolean;
}

const MapActionControlVagInt = ({
  mapType,
  handleMapType,
  classname,
  selectedRiskData,
  boundRef,
  isRequestingRiskanalysis,
  isOpenLayerList,
  isProjectView,
  handleOpenLayersList
}: MapActionControlVagIntProps) => {
  const map = useMap();
  const { selectedProject, projects } = useAppSelector((state) => state.projects);

  const handleZoomIn = () => map.zoomIn();
  const handleZoomOut = () => map.zoomOut();
  const handleCenter = () => {
    let centerLatLng;
    if (
      selectedRiskData &&
      selectedRiskData.geometry &&
      selectedRiskData.geometry.type === CoordinateType.MultiPolygon
    ) {
      centerLatLng = getCenterPosition(selectedRiskData.geometry.coordinates);
    } else if (
      selectedRiskData &&
      selectedRiskData.geometry &&
      selectedRiskData.geometry.type === CoordinateType.Polygon
    ) {
      centerLatLng = getCenterPositionPolygon(selectedRiskData.geometry.coordinates);
    }

    if (!isEmpty(centerLatLng)) {
      map.setView(centerLatLng, 18);
    } else if (!isEmpty(boundRef.current.getBounds())) {
      map.fitBounds(boundRef.current.getBounds());
    } else if (!isEmpty(selectedProject)) {
      map.setView(selectedProject.coordinates, 10);
    } else if (projects && projects.length > 0) {
      map.setView(projects[0].coordinates, 10);
    }
  };

  const handleChangeMapType = (mapType: string) => {
    handleMapType(mapType);
  };

  useEffect(() => {
    if (!isEmpty(selectedRiskData)) return;
    if (!isRequestingRiskanalysis) {
      setTimeout(() => {
        if (!isEmpty(boundRef.current.getBounds())) {
          map.fitBounds(boundRef.current.getBounds());
        }
      }, 0);
    }
  }, [isRequestingRiskanalysis, selectedRiskData]);

  const items: MenuProps['items'] = [
    {
      label: (
        <div onClick={() => handleChangeMapType(Maptype.Roadmap)}>
          <Radio value={Maptype.Roadmap} checked={mapType === Maptype.Roadmap} />
          <Text>ESRI Street Map</Text>
        </div>
      ),
      key: 'roadmap'
    },
    {
      label: (
        <div onClick={() => handleChangeMapType(Maptype.satellite)}>
          <Radio value={Maptype.satellite} checked={mapType === Maptype.satellite} />
          <Text>ESRI Satellite Map</Text>
        </div>
      ),
      key: 'satellite'
    }
  ];

  return (
    <Control position="topright">
      <div className={`${classname} map-action-control`}>
        {!isProjectView && (
          <Dropdown
            menu={{ items }}
            trigger={['click']}
            placement="topRight"
            overlayClassName="maplayer-dropdown"
          >
            <a onClick={(e) => e.preventDefault()}>
              <div className="map-layer">
                <MapLayerIcon />
              </div>
            </a>
          </Dropdown>
        )}
        {handleOpenLayersList && (
          <Button
            className="layer-list-icon"
            onClick={() => handleOpenLayersList(!isOpenLayerList)}
          >
            <MapLayerIcon />
          </Button>
        )}
        <Button className="zoom-plus" onClick={handleZoomIn}>
          <PlusIcon />
        </Button>
        <Button className="zoom-minus" onClick={handleZoomOut}>
          <MinusIcon />
        </Button>
        <Button className="map-icon" onClick={() => handleCenter()}>
          <RecenterIcon />
        </Button>
      </div>
    </Control>
  );
};

MapActionControlVagInt.defaultProps = {
  classname: '',
  selectedRiskData: null,
  isRequestingRiskanalysis: false,
  handleOpenLayersList: () => {},
  isOpenLayerList: false,
  isProjectView: false
};

export default MapActionControlVagInt;
